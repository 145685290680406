var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        "custom-class": "base-form",
        visible: _vm.dialogVisible,
        width: _vm.width,
        "v-on": _vm.$listeners,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "span",
        {
          staticStyle: { "text-align": "left" },
          attrs: { slot: "title" },
          slot: "title",
        },
        [_c("h3", [_vm._v(_vm._s(_vm.title))])]
      ),
      _c(
        "div",
        { staticClass: "body" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.tableLoading,
                  expression: "tableLoading",
                },
              ],
              attrs: {
                border: true,
                data: _vm.tableData,
                "header-cell-style": {
                  background: "#F4F6FA",
                  alignSelf: "center",
                  textAlign: "center",
                  fontSize: "13px",
                },
                "cell-style": {
                  alignSelf: "center",
                  textAlign: "center",
                  padding: "5px",
                  fontSize: "12px",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "expand" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "div-frac-left-right" }, [
                          _c(
                            "div",
                            { staticClass: "flex-row" },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item margin-right-8",
                                  attrs: {
                                    "popper-class": "new-tooltip",
                                    effect: "light",
                                    content:
                                      "A TxHash or transaction hash is a unique 66-character identifier that is generated whenever a transaction is executed.",
                                    placement: "top",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-question",
                                    staticStyle: { color: "#c0c0c0" },
                                  }),
                                ]
                              ),
                              _c("p", { staticClass: "font-small" }, [
                                _vm._v("Transaction Hash:"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "p",
                            { staticClass: "font-small blackchain-line-info" },
                            [_vm._v(_vm._s(scope.row.transactionHash))]
                          ),
                        ]),
                        _c("div", { staticClass: "div-frac-left-right" }, [
                          _c(
                            "div",
                            { staticClass: "flex-row" },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item margin-right-8",
                                  attrs: {
                                    "popper-class": "new-tooltip",
                                    effect: "light",
                                    content: "The status of the transaction.",
                                    placement: "top",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-question",
                                    staticStyle: { color: "#c0c0c0" },
                                  }),
                                ]
                              ),
                              _c("p", { staticClass: "font-small" }, [
                                _vm._v("Status:"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "status-tag" },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item margin-right-8",
                                  attrs: {
                                    "popper-class": "new-tooltip",
                                    effect: "light",
                                    content:
                                      "A Status code indicating if the top-level call succeeded or failed (applicable for Post BYZANTIUM blocks only)",
                                    placement: "top",
                                  },
                                },
                                [
                                  scope.row.status === true
                                    ? _c(
                                        "el-tag",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: "success",
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-success",
                                            staticStyle: {
                                              color: "green",
                                              "padding-right": "5px",
                                            },
                                          }),
                                          _vm._v(" Success"),
                                        ]
                                      )
                                    : _vm._e(),
                                  scope.row.status === false
                                    ? _c(
                                        "el-tag",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: "danger",
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-error",
                                            staticStyle: {
                                              color: "red",
                                              "padding-right": "5px",
                                            },
                                          }),
                                          _vm._v(" Unsuccessful"),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "div-frac-left-right" }, [
                          _c(
                            "div",
                            { staticClass: "flex-row" },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item margin-right-8",
                                  attrs: {
                                    "popper-class": "new-tooltip",
                                    effect: "light",
                                    content:
                                      "Number of the block in which the transaction is recorded. Block confirmations indicate how many blocks have been added since the transaction was mined.",
                                    placement: "top",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-question",
                                    staticStyle: { color: "#c0c0c0" },
                                  }),
                                ]
                              ),
                              _c("p", { staticClass: "font-small" }, [
                                _vm._v("Block:"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "p",
                            { staticClass: "font-small blackchain-line-info" },
                            [_vm._v(_vm._s(scope.row.blockNumber))]
                          ),
                        ]),
                        _c("div", { staticClass: "div-frac-left-right" }, [
                          _c(
                            "div",
                            { staticClass: "flex-row" },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item margin-right-8",
                                  attrs: {
                                    "popper-class": "new-tooltip",
                                    effect: "light",
                                    content:
                                      "The date and time at which a transaction is mined.",
                                    placement: "top",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-question",
                                    staticStyle: { color: "#c0c0c0" },
                                  }),
                                ]
                              ),
                              _c("p", { staticClass: "font-small" }, [
                                _vm._v("Timestamp:"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "p",
                            { staticClass: "font-small blackchain-line-info" },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.formatUtcTimeString(scope.row.updatedAt)
                                ) + " UTC"
                              ),
                            ]
                          ),
                        ]),
                        _c("div", { staticClass: "div-frac-left-right" }, [
                          _c(
                            "div",
                            { staticClass: "flex-row" },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item margin-right-8",
                                  attrs: {
                                    "popper-class": "new-tooltip",
                                    effect: "light",
                                    content:
                                      "The sending party of the transaction.",
                                    placement: "top",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-question",
                                    staticStyle: { color: "#c0c0c0" },
                                  }),
                                ]
                              ),
                              _c("p", { staticClass: "font-small" }, [
                                _vm._v("From:"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "p",
                            { staticClass: "font-small blackchain-line-info" },
                            [_vm._v(_vm._s(scope.row.fromAddress))]
                          ),
                        ]),
                        _c("div", { staticClass: "div-frac-left-right" }, [
                          _c(
                            "div",
                            { staticClass: "flex-row" },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item margin-right-8",
                                  attrs: {
                                    "popper-class": "new-tooltip",
                                    effect: "light",
                                    content:
                                      "The receiving party of the transaction (could be a contract address).",
                                    placement: "top",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-question",
                                    staticStyle: { color: "#c0c0c0" },
                                  }),
                                ]
                              ),
                              _c("p", { staticClass: "font-small" }, [
                                _vm._v("Interacted With (To):"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "p",
                            { staticClass: "font-small blackchain-line-info" },
                            [_vm._v("Contract " + _vm._s(scope.row.toAddress))]
                          ),
                        ]),
                        _c("div", { staticClass: "div-frac-left-right" }, [
                          _c(
                            "div",
                            { staticClass: "flex-row" },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item margin-right-8",
                                  attrs: {
                                    "popper-class": "new-tooltip",
                                    effect: "light",
                                    content:
                                      "Cost per unit of gas specified for the transaction, in Ether and Gwei. The higher the gas price the higher chance of getting included in a block.",
                                    placement: "top",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-question",
                                    staticStyle: { color: "#c0c0c0" },
                                  }),
                                ]
                              ),
                              _c("p", { staticClass: "font-small" }, [
                                _vm._v("Gas Used:"),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "p",
                            { staticClass: "font-small blackchain-line-info" },
                            [_vm._v(_vm._s(scope.row.gasUsed) + " Gwei")]
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "status", label: "Status", "min-width": "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status === true
                          ? _c(
                              "el-tag",
                              { attrs: { size: "small", type: "success" } },
                              [_vm._v("Success")]
                            )
                          : _vm._e(),
                        scope.row.status === false
                          ? _c(
                              "el-tag",
                              { attrs: { size: "small", type: "danger" } },
                              [_vm._v("Unsuccessful")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "transactionHash",
                  label: "Transaction Hash",
                  "min-width": "350",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "blockNumber",
                  label: "Block Number",
                  "min-width": "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-link", { attrs: { type: "primary" } }, [
                          _vm._v(_vm._s(scope.row.blockNumber)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "updatedAt",
                  label: "Timestamp",
                  "min-width": "200",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.formatUtcTimeString(scope.row.updatedAt))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "footer-button-bar",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _vm.showCloseButton
            ? _c(
                "el-button",
                {
                  staticStyle: { "min-width": "100px" },
                  attrs: { size: "small", round: "" },
                  on: { click: _vm.handleClose },
                },
                [_vm._v(" " + _vm._s(_vm.$t("Close")) + " ")]
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }